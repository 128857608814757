import React from 'react';
import './App.css';
import './skeleton.css';

function App() {
  return (
    <>
<div className="section hero">
    <div className="container">
        <div className="row">
            <div className="one-half column">
                <h4 className="hero-heading">A unified platform for building enterprise-grade conversational experiences</h4>
                <a className="coral-bg button button-primary border-none" href="http://app.fastbot.dev">Try Fastbot</a>
            </div>
            <div className="one-half column phones">
                <img className="phone" src="/images/fastbot.png" alt="Fastbot Icon"/>
            </div>
        </div>
    </div>
</div>


<div className="section get-help">
    <div className="container">
        <h3 className="section-heading">Need help getting started?</h3>
        <p className="section-description">Fastbot is a framework is help developers deploy bots quickly.
            It allows a developer to develop a bot once and deploy to multiple platforms</p>
        <a className="coral-bg button button-primary border-none" href="http://docs.fastbot.dev">View Fastbot Docs</a>
    </div>
</div>

<div className="section">
    <div className="container">
        <h3 className="coral-color section-heading">Supported Integrations</h3>
        <p className="section-description"></p>
        <div className="pt-10 row">
            <div className="two columns">
                <img className="icon-dimensions" src="/images/messenger.svg" alt="Messenger Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/slack.svg" alt="Slack Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/telegram.svg" alt="Telegram Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/twitter.svg" alt="Twitter Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/twilio.svg" alt="Twilio Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions " src="/images/line.svg" alt="Line Icon"/>
            </div>

        </div>
        <div className="pt-10 row" >
            <div className="two columns">
                <img className="icon-dimensions " src="/images/viber.svg" alt="Viber Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/kik.svg" alt="Kik Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/microsoft.svg" alt="Microsoft Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/teams.svg" alt="Teams Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/africastalking.svg" alt="Africa Stalking Icon"/>
            </div>
            <div className="two columns">
                <img className="icon-dimensions" src="/images/hubtel.png" alt="Hubtel Icon"/>
            </div>
        </div>
    </div>
</div>
</>
  );
}

export default App;
